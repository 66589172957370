<template>
  <div class="all_main_wrap">
    <div class="all_header_box">
      <div class="sider_left">
        <span class="title">
          <router-link
            class="crumbs_item"
            tag="a"
            to="/admin/OrderConfig/LineOrder"
          >线上订单 / </router-link>
          <span class="crumbs_item crumbs_last">退款详情</span>
        </span>
      </div>
    </div>
    <p class="headline">退款详情</p>
    <div class="bgDiv">
      <p class="detailMessage">退款状态：<span>待卖家处理退款</span></p>
      <p class="detailMessage">货物状态：未收到货</p>
      <p class="detailMessage">申请数量：1</p>
      <p class="detailMessage">申请时间：2022-01-01 23:00:00</p>
      <p class="detailMessage">退款原因：不想要了</p>
      <p class="detailMessage">申请金额：120.00元</p>
      <p class="detailMessage">退款方式：支付宝</p>
      <p class="detailMessage">退款编号：AIDEYANGANG</p>
      <p class="detailMessage"></p>
      <p class="detailMessage">买家补充描述：</p>
      <div class="supplementary">
        <p class="textDescription">我是退货原因我是退货原因我是退货原因我是退货原因我是退货原因我是退货原因我是退货原因我是退货原因我是退货原因我是退货原因</p>
        <div class="images">
          <img src="@/assets/image/Backlogin.png" alt=""/>
          <img src="@/assets/image/Backlogin.png" alt=""/>
          <img src="@/assets/image/Backlogin.png" alt=""/>
        </div>
      </div>
    </div>
    <!-- <p class="headline">买家补充描述：</p>
    <div class="bgDivLayout">
      <p class="textDescription">我是退货原因我是退货原因我是退货原因我是退货原因我是退货原因我是退货原因我是退货原因我是退货原因我是退货原因我是退货原因</p>
      <div class="images">
        <img src="@/assets/image/Backlogin.png" alt=""/>
        <img src="@/assets/image/Backlogin.png" alt=""/>
        <img src="@/assets/image/Backlogin.png" alt=""/>
      </div>
    </div> -->
    <!-- <span @click="showModal">添加地址 +</span> -->
    <p class="headline">买家退货地址</p>
    <div class="bgDivLayout">
      <a-radio-group v-model="value" @change="onChange">
        <a-radio :style="radioStyle" :value="1">
          <span class="addressMessage">姓名</span>
          <span class="addressMessage">123456456123</span>
          <span class="addressMessage">北京市北京市朝阳区一盒阳光大厦A座2345</span>
        </a-radio>
        <a-radio :style="radioStyle" :value="2">
          <span class="addressMessage">姓名</span>
          <span class="addressMessage">123456456123</span>
          <span class="addressMessage">北京市北京市朝阳区一盒阳光大厦A座2345</span>
        </a-radio>
        <a-radio :style="radioStyle" :value="3">
          <span class="addressMessage">姓名</span>
          <span class="addressMessage">123456456123</span>
          <span class="addressMessage">北京市北京市朝阳区一盒阳光大厦A座2345</span>
        </a-radio>
      </a-radio-group>
    </div>
    <p class="headline">买家寄回信息</p>
    <div class="bgDivLayout">
      <div class="returnMessage">
        <p class="title">寄件人：</p>
        <p class="value">王小国</p>
      </div>
      <div class="returnMessage">
        <p class="title">手机号码：</p>
        <p class="value">1425635736</p>
      </div>
      <div class="returnMessage">
        <p class="title">快递公司：</p>
        <p class="value">邮政快递</p>
      </div>
      <div class="returnMessage">
        <p class="title">运单号：</p>
        <p class="value">372867467264377236787</p>
        <p class="function" @click="copyText('复制的内容')">复制</p>
      </div>
    </div>
    <p class="message">退回金额：<span class="price">120.00元</span></p>
    <p class="message">协商历史：<span class="function" @click="historyVisible = true">查看协商</span></p>
    <div class="btns">
      <!-- <a-button type="primary" @click="confirm">确认收货</a-button> -->
      <a-button type="primary" @click="agree">同意退款</a-button>
      <a-button type="primary" @click="noAgree">不同意</a-button>
      <a-button class="all_boder_btn" @click="$router.go(-1)">返回</a-button>
    </div>
    <!-- 协商历史弹框 -->
    <a-modal
      title="协商历史"
      :visible="historyVisible"
      :footer="null"
      @cancel="historyhandleCancel"
    >
      <div class="history">
        <p class="historyItem">用户申请退款    2022-01-01 23:00:00</p>
        <p class="historyItem">货物状态：未收到货</p>
        <p class="historyItem">申请数量：1</p>
        <p class="historyItem">退款金额：¥120.00</p>
        <p class="historyItem">退款原因：不想要了</p>
        <p class="historyItem">退款描述：</p>
        <br><br>
        <p class="historyItem">成功退款¥120.00  2022/01/15  09:05:00</p>
        <br><br>
        <p class="historyItem">小夏确认收货   2022/01/18 09:00:00</p>
        <br><br>
        <p class="historyItem">用户寄回商品  2022/01/18 09:00:00</p>
        <p class="historyItem">寄件人：王小国</p>
        <p class="historyItem">手机号：14234256154</p>
        <p class="historyItem">快递公司：邮政快递</p>
        <p class="historyItem">运单号：142345242567782</p>
        <br><br>
        <p class="historyItem">小华同意退款 2022/01/12 23:00:00</p>
        <p class="historyItem">寄回信息：小夏 1234256527   北京市北京市朝阳区一盒阳光大厦A座2345</p>
        <br><br>
        <p class="historyItem">用户申请退款    2022-01-01 23:00:00</p>
        <p class="historyItem">货物状态：已收到货</p>
        <p class="historyItem">申请数量：1</p>
        <p class="historyItem">退款金额：¥120.00</p>
        <p class="historyItem">退款原因：不想要了</p>
        <p class="historyItem">退款描述：</p>
      </div>
    </a-modal>
    <!-- 添加地址弹框 -->
    <a-modal
      title="添加退货地址"
      :visible="addressVisible"
      :confirm-loading="confirmLoading"
      @cancel="handleCancel"
    >
      <template slot="footer">
        <a-button key="submit" type="primary" @click="onSubmit">
          确认
        </a-button>
      </template>
      <a-form-model
        ref="ruleForm"
        :model="form"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item ref="name" label="收货人" prop="name">
          <a-input
            placeholder="请输入收货人"
            v-model="form.name"
            @blur="
              () => {
                $refs.name.onFieldBlur();
              }
            "
          />
        </a-form-model-item>
        <a-form-model-item ref="phone" label="手机号" prop="phone">
          <a-input
            placeholder="请输入手机号"
            v-model="form.phone"
            @blur="
              () => {
                $refs.phone.onFieldBlur();
              }
            "
          />
        </a-form-model-item>
        <a-form-model-item label="收货地址" prop="address">
          <a-cascader :options="options" v-model="form.address" placeholder="请选择收货地址" @change="addressCascader" />
        </a-form-model-item>
        <a-form-model-item ref="addressDetail" label="详细地址" prop="addressDetail">
          <a-input
            placeholder="请输入详细地址"
            v-model="form.addressDetail"
            @blur="
              () => {
                $refs.addressDetail.onFieldBlur();
              }
            "
          />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import HeaderBox from '@/components/HeaderBox.vue'
export default {
  // 可用组件的哈希表
  components: { HeaderBox },
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      historyVisible: false, // 协商历史
      addressVisible: false, // 添加地址
      confirmLoading: false,
      value: 1,
      radioStyle: {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
      },
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      other: '',
      form: {
        name: '',
        address: null,
        phone: '',
        addressDetail:''
      },
      rules: {
        name: [
          { required: true, message: '请输入收货人', trigger: 'blur' },
        ],
        addressDetail: [
          { required: true, message: '请输入详细地址', trigger: 'blur' },
        ],
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { pattern: /^1[3-9]\d{9}$/, message: '请输入正确的手机号', trigger: 'blur' },
        ],
        address: [{ required: true, message: '请选择收货地址', trigger: 'change' }],
      },
      options: [
        {
          value: 'zhejiang',
          label: 'Zhejiang',
          children: [
            {
              value: 'hangzhou',
              label: 'Hangzhou',
              children: [
                {
                  value: 'xihu',
                  label: 'West Lake',
                },
              ],
            },
          ],
        },
        {
          value: 'jiangsu',
          label: 'Jiangsu',
          children: [
            {
              value: 'nanjing',
              label: 'Nanjing',
              children: [
                {
                  value: 'zhonghuamen',
                  label: 'Zhong Hua Men',
                },
              ],
            },
          ],
        },
      ],
    }
  },
  // 事件处理器
  methods: {
    // 关闭弹框
    historyhandleCancel(){
      this.historyVisible = false;
    },
    addressCascader(e){
      // 级联选择
      // console.log(e)
    },
    onSubmit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          alert('submit!');
        } else {
          // console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm() {
      this.$refs.ruleForm.resetFields();
    },
    // --------------添加地址begin
    showModal() {
      this.addressVisible = true;
    },
    handleOk(e) {
      this.ModalText = 'The modal will be closed after two seconds';
      this.confirmLoading = true;
      setTimeout(() => {
        this.addressVisible = false;
        this.confirmLoading = false;
      }, 2000);
    },
    handleCancel(e) {
      // console.log('Clicked cancel button');
      this.addressVisible = false;
    },
    // --------------添加地址end
    onChange(e) {
      // console.log('radio checked', e.target.value);
    },
    copyText(e){
      var input = document.createElement("input");   // 直接构建input
      input.value = e;  // 设置内容
      document.body.appendChild(input);    // 添加临时实例
      input.select();   // 选择实例内容
      document.execCommand("Copy");   // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$message.info('复制成功！');
    },
    // 确认收货
    confirm(){
      this.$confirm({
        title: '确认收货',
        content: '您确认收货后，金额将会原路退还至用户账号中，请确认商品是否已签收？',
        onOk() {
          return new Promise((resolve, reject) => {
            setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
          }).catch(() => console.log('Oops errors!'));
        },
        onCancel() {},
      });
    },
    // 同意退款
    agree(){
      this.$confirm({
        title: '同意退款',
        content: '您同意退款后，金额将会原路退还至用户账号中，是否同意用户退款？',
        onOk() {
          return new Promise((resolve, reject) => {
            setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
          }).catch(() => console.log('Oops errors!'));
        },
        onCancel() {},
      });
    },
    // 不同意
    noAgree(){
      this.$confirm({
        title: '不同意退款',
        content: '您是否已经与买家联系，协商暂不同意买家的退款申请？确认后将会关闭买家退款申请，买家仍可再次提出申请。',
        onOk() {
          return new Promise((resolve, reject) => {
            setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
          }).catch(() => console.log('Oops errors!'));
        },
        onCancel() {},
      });
    }
  },
  // 生命周期-实例创建完成后调用
  created () { },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
  /deep/.ant-modal-header{
    text-align: center;
    border: 0;
  }
  /deep/.ant-modal-footer{
    text-align: center;
    border: 0;
  }
  .btns{
    margin-top: 40px;
    .ant-btn{
      margin-right: 20px;
    }
  }
  .message{
    margin: 20px 0;
    font-size: 14px;
    font-weight: bold;
    color: #333333;
    line-height: 16px;
    .price{
      color:red;
    }
    .function{
      color: #2B6ED4;
      font-size: 12px;
      font-weight: initial;
      cursor: pointer;
    }
  }
  .addressMessage{
    display: inline-block;
    margin-right: 10px;
    font-size: 14px;
    color: #333333;
    line-height: 16px;
  }
  .headline{
    font-size: 14px;
    font-weight: bold;
    color: #333333;
    line-height: 16px;
    margin-top: 32px;
    margin-bottom: 16px;
    span{
      display: inline-block;
      margin-left: 50px;
      font-weight: 400;
      color: #2B6ED4;
      cursor: pointer;
    }
  }
  .bgDivLayout{
    background: #F6F6FC;
    border-radius: 5px;
    padding: 16px 20px;
    .returnMessage{
      display: flex;
      margin-bottom: 20px;
      font-size: 12px;
      color: #333333;
      line-height: 14px;
      .title{
        width: 60px;
        text-align: right;
      }
      .function{
        color: #2B6ED4;
        margin-left: 50px;
        cursor: pointer;
      }
    }
    .returnMessage:last-child{
      margin-bottom: 0;
    }
  }
  .bgDiv{
    background: #F6F6FC;
    border-radius: 5px;
    padding: 16px 20px;
    display: flex;
    flex-wrap: wrap;
    .supplementary{
      border-radius: 5px;
      border: 1px solid #C4C4C4;
      padding: 16px 16px 22px;
      margin-top: 16px;
      .textDescription{
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 22px;
      }
      .images{
        display: flex;
        flex-wrap: wrap;
        margin-top: 16px;
        img{
          width: 200px;
          margin-right: 24px;
          // height: 120px;
        }
      }
    }
    .detailMessage{
      width: 33.3%;
      font-size: 12px;
      color: #333333;
      line-height: 14px;
      span{
        color: red;
      }
    }
    .detailMessage:nth-child(n+4){
      margin-top: 30px;
    }
  }
</style>
